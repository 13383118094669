import { FaListAlt, FaLink } from 'react-icons/fa';
import { Box } from '@chakra-ui/react';

import { ProtectedRoot } from './ProtectedRoot';
import Auth from '../layouts/Auth';
import Loading from '../layouts/Loading';

import Home from '../views/Home';
import UserManagement from '../views/UserManagement';

import {
  PersonIcon,
  HomeIcon,
} from '../components/Icons/Icons';

import SurveyView from '../views/PublicSurveyView/SurveyView';
import PublicSurveyView from '../layouts/PublicSurvey';
import SurveyList from '../views/PublicSurveyView/SurveyList';
import PublicLogin from '../views/PublicSurveyView/PublicLogin';
import SurveyBuilder from '../views/SurveyManagement';
import SurveyEdit from '../views/SurveyManagement/SurveyEdit';
import SurveyAdd from '../views/SurveyManagement/SurveyAdd';
import SurveyLinkManagement from '../views/SurveyLinkManagement';
import SurveyReponseView from '../views/SurveyManagement/SurveyResponseView';

const protectedNavigations = [
  {
    path: 'home',
    name: 'Home',
    icon: <HomeIcon color="inherit" />,
    element: <Home />,
    component: Home,
  },
  {
    path: 'user-management',
    name: 'User Management',
    icon: <PersonIcon color="inherit" />,
    element: <UserManagement />,
    role: 'ROLE_ADMIN',
    component: UserManagement,
  },
  {
    path: 'survey',
    name: 'Survey Management',
    icon: <Box as={FaListAlt} boxSize="16px" />,
    element: <SurveyBuilder />,
    component: SurveyBuilder,
  },
  {
    path: 'link-management',
    name: 'Link Management',
    icon: <Box as={FaLink} boxSize="16px" />,
    element: <SurveyLinkManagement />,
    component: SurveyLinkManagement,
  },
];

const allRoutes = [
  {
    path: '/admin',
    element: <ProtectedRoot />,
    children: [...protectedNavigations.map((e) => ({
      path: e.path,
      element: e.element,
    })), {
      path: 'survey/:surveyId/edit',
      element: <SurveyEdit />,
    }, {
      path: 'survey/new',
      element: <SurveyAdd />,
    }, {
      path: 'survey/:surveyId/responses',
      element: <SurveyReponseView />
    }]
  },
  {
    path: '/public',
    element: <PublicSurveyView />,
    children: [
      {
        index: true,
        element: <PublicLogin />,
      },
      {
        path: 'list',
        element: <SurveyList />,
      },
      {
        path: 'survey',
        element: <SurveyView />,
      },
    ],
  },
  {
    path: '/admin/login',
    element: <Auth />,
  },
  {
    path: '/admin/loading',
    element: <Loading />,
  },
];

export {
  allRoutes,
  protectedNavigations,
};
