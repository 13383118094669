import {
  Button,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ProfileIcon, SettingsIcon } from '../Icons/Icons';
import ChangePasswordModal from '../../views/ChangePasswordModal';
import SidebarResponsive from "../Sidebar/SidebarResponsive";
import AuthService from '../../services/auth.service';
import { protectedNavigations } from '../../routes/routes';

export default function HeaderLinks(props) {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const navbarIcon = useColorModeValue('gray.500', 'gray.200');

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);

  const handleChangePassword = () => {
    setIsChangePasswordModalOpen(true);
  };

  const logout = async () => {
    await AuthService.logout();
    navigate('/admin/loading')
  }

  const getRoleNavigations = () => {
    if (!user.roles.includes('ROLE_ADMIN')) {
      return protectedNavigations.filter(e=>e.role !== "ROLE_ADMIN")
    } else {
      return protectedNavigations;
    }
  }

  return (
    <Flex
      pe={{ sm: '0px', md: '16px' }}
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
    >
      <Menu>
        <MenuButton>
          <Button
            ms="0px"
            px="0px"
            me={{ sm: '2px', md: '16px' }}
            color={navbarIcon}
            variant="transparent-with-icon"
            rightIcon={
              document.documentElement.dir ? (
                ''
              ) : (
                <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
              )
            }
            leftIcon={
              document.documentElement.dir ? (
                <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
              ) : (
                ''
              )
            }
          >
            <Text display={{ sm: 'none', md: 'flex' }}>{user.username}</Text>
          </Button>
        </MenuButton>
        <SidebarResponsive
          logoText={""}
          secondary={props.secondary}
          routes={getRoleNavigations()}
          // logo={logo}
          // {...rest}
        />
        <MenuList p="8px 8px">
          <Flex flexDirection="column">
            <MenuItem borderRadius="8px" mb="0px">
              <Button w="100%" variant="link" onClick={handleChangePassword}>
                Change Password
              </Button>
            </MenuItem>
            <MenuItem borderRadius="8px" mb="0px">
              <Button w="100%" variant="link" onClick={logout}>Logout</Button>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onClose={() => setIsChangePasswordModalOpen(false)}
      />
    </Flex>

  );
}
