import React from 'react';
import { useNavigate } from 'react-router-dom';
import SurveySchemaCreator from './SurveySchemaCreator';
import surveyService from '../../services/survey.service';

const SurveyAdd = () => {
    const navigate = useNavigate();

    const saveSurvey = async (data) => {
        console.log(data);
        const response = await surveyService.newSurvey({ schema: data.schema, endTime: data.endTime });
        if (response === true) {
            navigate('/admin/survey');
        } else {
            alert('error');
        }
    }

    return (
        <SurveySchemaCreator
            onSchemaCreated={(data) => {
                saveSurvey(data);
            }}
        />
    );
};

export default SurveyAdd;
