import {
  VStack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { DateTime } from 'luxon';

import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import SurveyListTableRow from './SurveyListTableRow';

function SurveyListTable({ title, captions, data, endTime }) {
  const textColor = useColorModeValue('gray.700', 'white');
  const endTimeString = DateTime.fromISO(endTime).toFormat("dd/MM/yyyy HH:mm");
  return (
    <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {title}
        </Text>
      </CardHeader>
      <CardBody>
        <VStack width="100%">
          <Text width="100%" textAlign={"start"} fontSize="sm">Your link will be expired on {endTimeString}. </Text>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                {captions.map((caption, idx) => (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? '0px' : null}>
                    {caption}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row) => (
                <SurveyListTableRow title={row.schema.title} endTime={row.endTime} timeLimit={row.schema.maxTimeToFinish} isCompleted={row.isCompleted} surveyId={row._id} />
              ))}
            </Tbody>
          </Table>
        </VStack>
      </CardBody>
    </Card>
  );
}

export default SurveyListTable;
