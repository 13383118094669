import React, { useEffect, useState } from 'react';
import { Flex, Center } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

import SurveyListTable from './SurveyListTable';
import { setEmail, setSurveyList } from '../../slices/public';
import publicService from '../../services/public.service';

export default function SurveyList() {
  const { surveyList } = useSelector((state) => state.public);
  const [endTime, setEndTime] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getData = async () => {
    try {
      setIsLoading(true);
      const result = await publicService.publicLogin(localStorage.getItem('email'), localStorage.getItem('link'));
      dispatch(setEmail(result.email));
      dispatch(setSurveyList(result.surveys));
      setEndTime(result.linkEndTime);
    } catch {
      navigate('/public');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return (
      <Center w="100%" h="100vh">
        <ReactLoading type="bars" color="rgb(130 229 209)" height="5%" width="5%" />
      </Center>
    )
  }

  return (
    <Flex direction="column">
      <SurveyListTable
        title="Survey List"
        captions={['Title', 'Time Limit', 'Status', '']}
        endTime={endTime}
        data={surveyList}
      />
    </Flex>
  );
}
