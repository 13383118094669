import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack,
    HStack,
    Select,
    IconButton,
    Text,
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';

const QuestionEditor = ({ onSubmit, editingQuestion, onCancelEdit, defaultQuestionName, allowedTypes = ['multipleChoice', 'text'], handleClose }) => {
    const [questionName, setQuestionName] = useState('');
    const [questionTitle, setQuestionTitle] = useState('');
    const [questionType, setQuestionType] = useState('text');
    const [options, setOptions] = useState([{ text: '', value: '' }]);

    useEffect(() => {
        if (editingQuestion) {
            setQuestionTitle(editingQuestion.title);
            setQuestionName(editingQuestion.name);
            setQuestionType(editingQuestion.type);
            setOptions(editingQuestion.options || [{ text: '', value: '' }]);
        } else {
            setQuestionTitle('');
            setQuestionName(defaultQuestionName);
            setQuestionType('text');
            setOptions([{ text: '', value: '' }]);
        }
    }, [editingQuestion, defaultQuestionName]);

    const updateOption = (index, field, value) => {
        const newOptions = [...options];
        newOptions[index][field] = value;

        setOptions(newOptions);
    };

    const copyTextToValue = (index, field, value) => {
        const newOptions = [...options];
        newOptions[index][field] = value;
        if (newOptions[index]['value'] === '') {
            newOptions[index]['value'] = generateValueFromLabel(value);
        }
        setOptions(newOptions);
    }

    const addOption = () => {
        setOptions([...options, { text: '', value: '' }]);
    };

    const removeOption = (index) => {
        setOptions(options.filter((_, i) => i !== index));
    };

    const isValidQuestion = () => {
        if (!questionName.trim()) {
            alert('Question name cannot be empty');
            return false;
        }

        if (questionType === 'multipleChoice') {
            const invalidOption = options.find(
                (option) => !option.text.trim() || !option.value.trim()
            );

            if (invalidOption) {
                alert('Both text and value must be provided for all options');
                return false;
            }
        }

        return true;
    };

    const handleSubmit = () => {
        if (!isValidQuestion()) return;

        let validOptions = [];
        if (questionType === 'multipleChoice') {
            validOptions = options.filter(
                (option) => option.text.trim() && option.value.trim()
            );
        }

        onSubmit({
            name: questionName,
            title: questionTitle,
            type: questionType,
            options: questionType === 'text' ? [] : validOptions,
        });

        setQuestionName('');
        setQuestionTitle('');
        setQuestionType('text');
        setOptions([{ text: '', value: '' }]);
    };

    const generateValueFromLabel = (label) => {
        return label.trim().toLowerCase().replace(/\s+/g, '_');
    };

    return (
        <Box>
            <FormControl mt={4}>
                <FormLabel>Question Name</FormLabel>
                <Input
                    value={questionName}
                    onChange={(e) => setQuestionName(e.target.value)}
                    placeholder="Enter question name"
                    disabled={editingQuestion}
                />
            </FormControl>
            <FormControl>
                <FormLabel>Question Title</FormLabel>
                <Input
                    value={questionTitle}
                    onChange={(e) => setQuestionTitle(e.target.value)}
                    placeholder="Enter question title"
                />
            </FormControl>

            <FormControl mt={4}>
                <FormLabel>Question Type</FormLabel>
                <Select value={questionType} onChange={(e) => setQuestionType(e.target.value)}>
                    {allowedTypes.includes('text') && <option value="text">Text</option>}
                    {allowedTypes.includes('multipleChoice') && (
                        <option value="multipleChoice">Multiple Choice</option>
                    )}
                </Select>

            </FormControl>
            {questionType === 'multipleChoice' && (
                <VStack mt={4} spacing={2}>
                    <Text fontWeight="bold">Options</Text>
                    {options.map((option, index) => (
                        <HStack key={index} spacing={4}>
                            <Input
                                value={option.text}
                                onChange={(e) => updateOption(index, 'text', e.target.value)}
                                onBlur={(e) => copyTextToValue(index, 'text', e.target.value)}
                                placeholder={`Option ${index + 1} Label`}
                            />
                            <Input
                                value={option.value}
                                onChange={(e) => updateOption(index, 'value', e.target.value)}
                                placeholder={`Option ${index + 1} Value`}
                            />
                            <IconButton
                                icon={<MinusIcon />}
                                onClick={() => removeOption(index)}
                                isDisabled={options.length <= 1}
                                aria-label="Remove Option"
                            />
                        </HStack>
                    ))}
                    <Button leftIcon={<AddIcon />} onClick={addOption}>
                        Add Option
                    </Button>
                </VStack>
            )}

            <HStack mt={8} mb={4} spacing={4} justifyContent="flex-end">
                <Button onClick={handleSubmit} colorScheme="blue">{editingQuestion ? 'Save' : 'Add'}</Button>
                <Button variant={"outline"} onClick={handleClose}>Close</Button>
            </HStack>
        </Box>
    );
};

export default QuestionEditor;
