// chakra imports
import { Box, ChakraProvider } from '@chakra-ui/react';

import React from 'react';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import theme from '../theme/theme';
import Login from '../views/Login';

export default function Pages() {
  const wrapper = React.createRef();
  React.useEffect(() => {
    document.body.style.overflow = 'unset';
    return function cleanup() {};
  });

  const navRef = React.useRef();
  document.documentElement.dir = 'ltr';
  return (
    <ChakraProvider theme={theme} resetCss={false} w="100%">
      <Box ref={navRef} w="100%">
        <Box w="100%">
          <Box ref={wrapper} w="100%">
            <Login />
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
}
