import React, { useState, useEffect } from 'react';
import { Flex, Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Button, Text, useSafeLayoutEffect, HStack } from '@chakra-ui/react';
import { useParams, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import * as XLSX from 'xlsx';

import SurveyService from '../../services/survey.service';

const SurveyResponseView = () => {
    const { state } = useLocation();
    const { surveyTitle } = state;
    const [responses, setResponses] = useState([]);
    const [calculations, setCalculations] = useState([]);
    const [headers, setHeaders] = useState([]);
    const { surveyId } = useParams();

    const formatDate = (dateString) => {
        const date = DateTime.fromISO(dateString);
        return date.toFormat('yyyy-MM-dd HH:mm');
    };

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const responses = await SurveyService.getSurveyResponses(surveyId);
                setResponses(responses);

                // Find unique keys in all response objects
                const uniqueKeys = new Set();
                responses.forEach((response) => {
                    if (response.response) {
                        Object.keys(response.response).forEach((key) => uniqueKeys.add(key));
                    }
                });
                setHeaders(['Email', 'First Name', 'Last Name', 'Submit Time', 'Version', ...Array.from(uniqueKeys).sort()]);

                const survey = await SurveyService.getSurveyDetail(surveyId);
                setCalculations(survey.overallCalculation);


            } catch (error) {
                console.error('Error fetching survey responses:', error);
            }
        };

        fetchResponses();
    }, [surveyId]);

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(
            responses.map((response) => ({
                Email: response.email,
                'Submit Time': formatDate(response.endTime),
                ...response.response,
            }))
        );
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, surveyTitle);
        XLSX.writeFile(wb, 'survey_responses.xlsx');
    };

    return (
        <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
            <Box>
                <HStack justifyContent={"space-between"} mb={4}>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>{surveyTitle}</Text>
                    <Button mb={4} onClick={exportToExcel} variant={'outline'}>
                        Export to Excel
                    </Button>
                </HStack>
                <Text fontSize="xl">Resposnes</Text>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            {headers.map((header, index) => (
                                <Th key={index}>{header}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {responses.map((response) => (
                            <Tr key={response._id}>
                                <Td>{response.email}</Td>
                                <Td>{response.firstName}</Td>
                                <Td>{response.lastName}</Td>
                                <Td>{formatDate(response.endTime)}</Td>
                                <Td>{response.version}</Td>
                                {headers.slice(5).map((header) => (
                                    <Td key={header}>
                                        {response.response && typeof response.response[header] === 'object'
                                            ? JSON.stringify(response.response[header])
                                            : response.response?.[header] ?? ''
                                        }
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>

                <Text fontSize="xl" mt={8}>Overall Statistics</Text>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Td>Name</Td>
                            <Td>Result</Td>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {calculations && calculations.map((calculation, index) => (
                            <Tr key={index}>
                                <Td>{calculation.name}</Td>
                                <Td>{calculation.value && Object.keys(calculation.value).map((e) => (`${e}: ${Math.round(calculation.value[e] * 100) / 100}\n`))}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Flex>
    );
};

export default SurveyResponseView;
