import React from 'react';
import { Divider, Flex, FormControl, FormLabel, Checkbox, Heading, VStack, Box, Button, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Input, HStack } from '@chakra-ui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
const PageManagement = ({ pages, setPages, selectedPage, handleTabChange, handleAddPage, handleDeletePage, handleAddQuestionClick, handleEditQuestion, handleDeleteQuestion }) => {
    // Your code for handling pages and related logic

    const DraggableQuestion = ({ question, index, moveQuestion, isLast }) => {
        const [{ isDragging }, drag] = useDrag(() => ({
            type: 'question',
            item: { index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }));

        const [, drop] = useDrop(() => ({
            accept: 'question',
            hover: (item) => {
                if (item.index === index) {
                    return;
                }
                moveQuestion(item.index, index);
                item.index = index;
            },
        }));

        return (
            <div
                ref={(node) => drag(drop(node))}
                style={{ opacity: isDragging ? 0.5 : 1, width: '100%' }}
            >
                {renderQuestion(question, index, isLast)}
            </div>
        );
    };

    const moveQuestion = (fromIndex, toIndex) => {
        const updatedQuestions = [...page.questions];
        const movedQuestion = updatedQuestions.splice(fromIndex, 1)[0];
        updatedQuestions.splice(toIndex, 0, movedQuestion);
        let tempPages = [...pages];
        let page = {
            ...tempPages[selectedPage],
            questions: updatedQuestions
        }
        tempPages[selectedPage] = page;
        setPages(tempPages);
    };

    const updateRandomNumber = (value) => {
        let tempPages = [...pages];
        let page = {
            ...tempPages[selectedPage],
            randomNumber: value,
        };
        tempPages[selectedPage] = page;
        setPages(tempPages);
    };

    const renderQuestion = (question, index, isLast) => {
        return (
            <Box key={index} width="100%">
                <Flex justifyContent="space-between" alignItems="center">
                    <Button
                        variant="ghost"
                        width="100%"
                        justifyContent="start"
                        textAlign="left"
                        onClick={() => handleEditQuestion(index)}
                        _hover={{
                            bg: "gray.200",
                        }}
                        mr={2}
                    >
                        {question.name &&
                            (question.name + ":" + question.title)
                        }

                        {question.type === 'multipleChoice' && (
                            " (Multiple Choice)"
                        )}
                        {question.type === 'text' && (
                            " (Text)"
                        )}
                    </Button>
                    <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDeleteQuestion(index)}
                    >
                        Delete
                    </Button>
                </Flex>
                {!isLast && (
                    <Divider mt={2} mb={2} width="100%" borderColor="gray.300" />
                )}
            </Box>
        );
    };


    return (
        <Box mt={8} style={{ border: '1px solid', borderColor: 'lightgray', padding: '8px' }}>
            <Tabs index={selectedPage} onChange={(index) => handleTabChange(index)}>
                <TabList>
                    {pages.map((_, index) => (
                        <Tab key={index}>Page {index + 1}</Tab>
                    ))}
                    <Button variant={'outline'} mb={1} onClick={handleAddPage}>Add Page</Button>
                </TabList>
                <TabPanels>
                    {pages.map((page, pageIndex) => (
                        <TabPanel key={pageIndex} p={4}>
                            <HStack>
                                <FormControl mt={4}>
                                    <FormLabel>Randomize Questions Order</FormLabel>
                                    <Checkbox
                                        isChecked={page.questionsOrder === 'random' ? true : false}
                                        onChange={(e) => {
                                            let tempPages = [...pages];
                                            let page = {
                                                ...tempPages[selectedPage],
                                                questionsOrder: e.target.checked === true ? 'random' : 'initial',
                                            }
                                            if (e.target.checked === true) {
                                                page = {
                                                    ...page,
                                                    randomNumber: tempPages[selectedPage].questions.length,
                                                }
                                            }
                                            tempPages[selectedPage] = page;
                                            setPages(tempPages);
                                        }}
                                    >
                                        Randomize questions order
                                    </Checkbox>
                                </FormControl>
                                <FormControl mt={4}>
                                    <FormLabel>Number of questions to be displayed</FormLabel>
                                    <Input
                                        maxWidth={'100px'}
                                        type="number"
                                        value={page.randomNumber}
                                        onChange={(e) => updateRandomNumber(e.target.value)}
                                        isDisabled={page.questionsOrder === 'random' ? false : true}
                                    />
                                    {page.randomNumber > page.questions.length && (
                                        <Text color="red" mt={1} fontSize="sm">
                                            Exceeds the total number of questions
                                        </Text>
                                    )}
                                </FormControl>
                            </HStack>

                            <Heading size="md" mt={4}>
                                Questions (Click to edit, Drag to reorder)
                            </Heading>
                            <Box
                                bg="gray.100"
                                borderRadius="md"
                                p={4}
                                mt={2}
                                boxShadow="sm"
                                borderWidth="1px"
                                borderColor="gray.200"
                            >
                                <DndProvider backend={HTML5Backend}>
                                    <VStack spacing={4} alignItems="start">
                                        {page.questions.length > 0 ? (
                                            page.questions.map((question, index) => (
                                                <DraggableQuestion
                                                    key={index}
                                                    question={question}
                                                    index={index}
                                                    moveQuestion={moveQuestion}
                                                    isLast={index + 1 === page.questions.length}
                                                />
                                            ))
                                        ) : (
                                            <Text fontStyle="italic" color="gray.600">
                                                No questions yet.
                                            </Text>
                                        )}
                                    </VStack>
                                </DndProvider>
                            </Box>
                            <Box>
                                <Button mt={4} mr={4} onClick={handleAddQuestionClick}>
                                    Add Question
                                </Button>
                                {pages.length > 1 && (
                                    <Button
                                        mt={4}
                                        mr={4}
                                        colorScheme="red"
                                        onClick={() => handleDeletePage(pageIndex)}
                                    >
                                        Delete Page
                                    </Button>
                                )}
                            </Box>

                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default PageManagement;