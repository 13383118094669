import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Center, } from '@chakra-ui/react';
import ReactLoading from 'react-loading';
import SurveySchemaCreator from './SurveySchemaCreator';
import surveyService from '../../services/survey.service';

const SurveyEdit = () => {
    const { surveyId } = useParams();
    const navigate = useNavigate();
    const [survey, setSurvey] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchSurvey(surveyId);
    }, [surveyId]);

    const fetchSurvey = async (id) => {
        setIsLoading(true);
        const response = await surveyService.getSurveyDetail(id);
        setSurvey(response);
        setIsLoading(false);
    };

    const saveSurvey = async (data) => {
        console.log(data);
        const response = await surveyService.saveSurveyDraft(surveyId, data.schema);
        // const response = await surveyService.updateSurvey(surveyId, { title: data.title, schema: data.schema, endTime: data.endTime });
        if (response === true) {
            navigate('/admin/survey');
        } else {
            alert('error');
        }
    }

    return (
        <>
            {isLoading ? (
                <Center w="100%" h="100vh">
                    <ReactLoading type="bars" color="rgb(130 229 209)" height="5%" width="5%" />
                </Center>
            ) : (
                <SurveySchemaCreator
                    surveyId={surveyId}
                    survey={survey}
                    isEditMode={true}
                    onSchemaCreated={(data) => {
                        saveSurvey(data);
                    }}
                />
            )}
        </>
    );
};

export default SurveyEdit;
