import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  VStack,
  FormErrorMessage,
  Checkbox,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { Formik, Field } from 'formik';

import { login, verifyToken } from '../slices/auth';
import { clearMessage } from '../slices/message';

import signInImage from '../assets/img/signInImage.png';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const titleColor = useColorModeValue('teal.300', 'teal.200');
  const textColor = useColorModeValue('gray.400', 'white');

  const initialValues = {
    username: '',
    password: '',
    rememberMe: false,
  };

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(verifyToken());
  }, [dispatch]);

  const loginSubmit = (values) => {
    const { username, password } = values;
    setLoading(true);

    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        navigate('/admin/home');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/admin/home" />;
  }

  return (
    <Flex position="relative">
      <Flex
        h={{ sm: 'initial', md: '75vh', lg: '100vh' }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        pt={{ sm: '100px', md: '0px' }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: 'none' }}
          w={{ base: '100%', md: '50%', lg: '42%' }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: '150px', lg: '80px' }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Welcome Back
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Enter your email and password to sign in
            </Text>
            <Formik
              initialValues={initialValues}
              onSubmit={loginSubmit}
            >
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <VStack spacing={4} align="flex-start">
                    <FormControl isInvalid={!!errors.username && touched.username}>
                      <FormLabel htmlFor="username">Username</FormLabel>
                      <Field
                        as={Input}
                        id="username"
                        name="username"
                        variant="filled"
                        validate={(value) => {
                          let error;
                          if (value === '') {
                            error = 'Username cannot be empty';
                          }
                          return error;
                        }}
                      />
                      <FormErrorMessage>{errors.username}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.password && touched.password}>
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <Field
                        as={Input}
                        id="password"
                        name="password"
                        type="password"
                        variant="filled"
                        validate={(value) => {
                          let error;
                          if (value === '') {
                            error = 'Password cannot be empty';
                          }
                          return error;
                        }}
                      />
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                    {loading ? <Center w="100%"><Spinner /></Center>
                      : (
                        <Button
                          fontSize="15px"
                          type="submit"
                          bg="teal.300"
                          w="100%"
                          h="45"
                          mb="20px"
                          color="white"
                          mt="20px"
                          _hover={{
                            bg: 'teal.200',
                          }}
                          _active={{
                            bg: 'teal.400',
                          }}
                        >
                          SIGN IN
                        </Button>
                      )}
                  </VStack>
                </form>
              )}

            </Formik>
            {message
              && (
                <Alert status="error" mt="20px">
                  <AlertIcon />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{message}</AlertDescription>
                </Alert>
              )}
          </Flex>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
          />
        </Box>

      </Flex>
    </Flex>
  );
}

export default Login;
