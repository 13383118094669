import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Tag,
    TagLabel,
    TagCloseButton,
    Button,
    FormErrorMessage,
} from "@chakra-ui/react";
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';


const SurveyLinkModal = ({ isOpen, onClose, onSubmit, surveyOptions, currentSurveyLink }) => {
    const now = new Date();
    now.setSeconds(0);
    now.setMilliseconds(0);

    const [surveys, setSurveys] = useState([]);
    const [emails, setEmails] = useState([]);
    const [newEmail, setNewEmail] = useState('');
    const [selectedSurveyOption, setSelectedSurveyOption] = useState();
    const [formData, setFormData] = useState(currentSurveyLink || { title: "", surveys: [], emails: [], endTime: "" });
    const [titleError, setTitleError] = useState('');

    useEffect(() => {
        if (currentSurveyLink) {
            console.log(currentSurveyLink.surveys);
            setSurveys(currentSurveyLink.surveys);
            setEmails(currentSurveyLink.emails);
            setFormData(currentSurveyLink);
        } else {
            setSurveys([]);
            setEmails([]);
            setFormData({ title: "", surveys: [], emails: [], endTime: "" });
        }
        setTitleError('');
    }, [currentSurveyLink]);

    const handleSurveyChange = (selected) => {
        if (surveys.filter(e => e._id === selected.value).length === 0) {
            setSurveys([...surveys,  { _id: selected.value, title: selected.label }]);
        }
        setSelectedSurveyOption();
    };

    const addEmail = () => {
        if (newEmail) {
            setEmails([...emails, newEmail]);
            setNewEmail('');
        }
    };

    const removeSurvey = (index) => {
        setSurveys(surveys.filter((_, i) => i !== index));
    };

    const removeEmail = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.title || formData === "") {
            setTitleError('Title is required.');
            return;
        }
        formData.surveys = surveys.map(s => s._id);
        formData.emails = emails;
        setTitleError('');
        onSubmit(formData);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{currentSurveyLink && currentSurveyLink._id ? "Edit Survey Link" : "Add Survey Link"}</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <FormControl id="title" mb={4} isInvalid={!!titleError} isRequired>
                            <FormLabel>Title</FormLabel>
                            <Input
                                type="text"
                                value={formData.title}
                                onChange={e => setFormData({ ...formData, title: e.target.value })}
                                required
                            />
                        <FormErrorMessage>{titleError}</FormErrorMessage>
                        </FormControl>
                        <FormControl id="surveys" mb={4}>
                            <FormLabel>Surveys</FormLabel>
                            {surveys.map((survey, index) => (
                                <Tag key={index} size="md" m={1}>
                                    <TagLabel>{survey.title}</TagLabel>
                                    <TagCloseButton onClick={() => removeSurvey(index)} />
                                </Tag>
                            ))}
                            <Select
                                options={surveyOptions}
                                value={selectedSurveyOption}
                                onChange={handleSurveyChange}
                                placeholder="Add a survey"
                                isSearchable
                                isClearable
                            />
                        </FormControl>
                        <FormControl id="emails" mb={4}>
                            <FormLabel>Emails</FormLabel>
                            {emails.map((email, index) => (
                                <Tag key={index} size="md" m={1}>
                                    <TagLabel>{email}</TagLabel>
                                    <TagCloseButton onClick={() => removeEmail(index)} />
                                </Tag>
                            ))}
                            <Input
                                type="email"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                                placeholder="Add an email"
                            />
                            <Button mt={2} onClick={addEmail}>Add Email</Button>
                        </FormControl>
                        <FormControl id="end-time" mt={4}>
                            <FormLabel>End Time</FormLabel>
                            <DateTimePicker
                                value={formData.endTime}
                                onChange={e => setFormData({ ...formData, endTime: e })}
                                format={"dd/MM/yyyy HH:mm"}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} type="submit">
                            {currentSurveyLink && currentSurveyLink._id ? "Update" : "Add"}
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default SurveyLinkModal;
