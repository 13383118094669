import axiosInstance from './axiosIntance';

const getSurveyLinks = async () => {
  try {
    const result = await axiosInstance.get(`/survey-links`);
    return result.data;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
};

const updateSurveyLink = async (id, data) => {
  try {
    const result = await axiosInstance.put(`/survey-links?surveyId=${id}`, data);
    return result;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const createSurveyLink = async (data) => {
  try {
    const result = await axiosInstance.post(`/survey-links`, {data});
    return result;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const deleteSurveyLink = async (id) => {
  try {
    const result = await axiosInstance.delete(`/survey-links?surveyId=${id}`);
    return result;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const functions = {
    getSurveyLinks,
    updateSurveyLink,
    createSurveyLink,
    deleteSurveyLink
};

export default functions;
