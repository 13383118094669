import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Checkbox,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useToast,
  FormErrorMessage
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import usermanagementService from '../../services/usermanagement.service';

function UserEditModal({ isOpen, onClose, id, email, roles, isCreateMode, username, password }) {
  const [updatedUsername, setUpdatedUsername] = useState(username ?? '');
  const [updatedEmail, setUpdatedEmail] = useState(email ?? '');
  const [updatedPassword, setUpdatedPassword] = useState(password ?? '');
  const [updatedRoles, setUpdatedRoles] = useState(roles);

  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [rolesError, setRolesError] = useState('');

  const toast = useToast();

  useEffect(() => {
    if (!isCreateMode) {
      setUpdatedUsername(username ?? '');
      setUpdatedPassword(password ?? '');
      setUpdatedEmail(email);
      setUpdatedRoles(roles);
    } else {
      setUpdatedUsername('');
      setUpdatedPassword('');
      setUpdatedEmail('');
      setUpdatedRoles([]);
    }
  }, [isCreateMode, id, email, roles, username, password]);

  useEffect(() => {
    setUsernameError('');
    setEmailError('');
    setPasswordError('');
    setRolesError('');
  }, [isCreateMode, id]);

  // Validation functions
  const validateUsername = () => {
    if (!updatedUsername) {
      setUsernameError('Username is required.');
      return false;
    }
    setUsernameError('');
    return true;
  };

  const validateEmail = () => {
    if (!updatedEmail) {
      setEmailError('Email is required');
      return false;
    }

    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(updatedEmail)) {
      setEmailError('Please enter a valid email address');
      return false;
    }

    setEmailError('');
    return true;
  };

  const validatePassword = () => {
    if (isCreateMode && !updatedPassword) {
      setPasswordError('Password is required.');
      return false;
    }
    setPasswordError('');
    return true;
  };

  const validateRoles = () => {
    if (updatedRoles.length === 0) {
      setRolesError('Role is required.');
      return false;
    } else {
      return true;
    }
  }

  const resetFields = () => {
    setUpdatedUsername('');
    setUpdatedEmail('');
    setUpdatedPassword('');
    setUpdatedRoles([]);
    setUsernameError('');
    setEmailError('');
    setPasswordError('');
    setRolesError('');
  };

  const handleSubmit = async () => {
    try {
      if (isCreateMode) {
        // Create user data here
        const isUsernameValid = validateUsername();
        const isEmailValid = validateEmail();
        const isPasswordValid = validatePassword();
        const isRolesValid = validateRoles();

        if (!isUsernameValid || !isEmailValid || !isPasswordValid || !isRolesValid) {
          return false;
        }

        await usermanagementService.createUser(updatedUsername, updatedEmail, updatedPassword, updatedRoles);
        toast({
          title: 'User created successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        resetFields();
        onClose();
      } else {
        const isRolesValid = validateRoles();
        if (!isRolesValid) {
          return false;
        }
        // Update user data here
        await usermanagementService.updateUserDetails(id, updatedEmail, updatedPassword, updatedRoles);
        toast({
          title: 'User details updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
      }
    } catch (err) {

      toast({
        title: isCreateMode ? `Cannot create user: ${err}` : 'Cannot update user data',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }

  };

  const toggleRole = (role) => {
    if (updatedRoles.includes(role)) {
      setUpdatedRoles(updatedRoles.filter((r) => r !== role));
    } else {
      setUpdatedRoles([...updatedRoles, role]);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isCreateMode ? 'Create New User' : 'Edit User Details'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isCreateMode && (
            <FormControl mt={4} isInvalid={!!usernameError}>
              <FormLabel>Username</FormLabel>
              <Input
                type="text"
                value={updatedUsername}
                onChange={(e) => setUpdatedUsername(e.target.value)}
              />
              <FormErrorMessage>{usernameError}</FormErrorMessage>
            </FormControl>
          )}
          <FormControl mt={4} isInvalid={!!emailError}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={updatedEmail}
              onChange={(e) => setUpdatedEmail(e.target.value)}
            />
            <FormErrorMessage>{emailError}</FormErrorMessage>
          </FormControl>
          <FormControl mt={4} isInvalid={!!passwordError}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={updatedPassword}
              placeholder="for reset password only"
              onChange={(e) => setUpdatedPassword(e.target.value)}
            />
            <FormErrorMessage>{passwordError}</FormErrorMessage>
          </FormControl>
          <FormControl mt={4} isInvalid={!!rolesError}>
            <FormLabel>
              Roles:
            </FormLabel>
            <VStack align="start" spacing={2}>
              <Checkbox
                isChecked={updatedRoles && updatedRoles.includes("Admin")}
                onChange={() => toggleRole("Admin")}
                colorScheme="teal"
              >
                Admin
              </Checkbox>
              <Checkbox
                isChecked={updatedRoles && updatedRoles.includes("User")}
                onChange={() => toggleRole("User")}
                colorScheme="teal"
              >
                User
              </Checkbox>
            </VStack>
            <FormErrorMessage>{rolesError}</FormErrorMessage>
          </FormControl>

        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UserEditModal;
