import React, { useState } from 'react';
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Text,
  Select,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tag,
} from '@chakra-ui/react';

import DateTimePicker from 'react-datetime-picker';
import surveyService from '../../../../services/survey.service';
import axiosInstance from '../../../../services/axiosIntance';

const SurveyInformation = ({
  survey,
  selectedVersion,
  handleVersionChange,
  title,
  setTitle,
  instruction,
  setInstruction,
  endTime,
  handleEndTimeChange,
  timer,
  setTimer,
  image,
  setImage
}) => {

  const generateVersions = (latestVersion) => {
    const versions = [];
    for (let i = latestVersion - 1; i > 0; i--) {
      versions.push(i);
    }
    return versions;
  };

  const versions = survey ? generateVersions(survey.schema.version) : [];

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const filename = await surveyService.uploadImage(file);
    setImage(filename);
  };

  return (
    <Box>
      <Heading size="md" mt={4} mb={4}>
        Survey Information {(survey === undefined || survey.isDraft === true) && <Tag>Draft</Tag>}
      </Heading>
      {survey && (
        <FormControl mt={4}>
          <FormLabel>Latest Version</FormLabel>
          <Text mb={4}>{survey.schema.version}</Text>
          <Select
            value={selectedVersion}
            onChange={(e) => handleVersionChange(e.target.value)}
            placeholder="Select a version"
          >
            <option key="current" value={"current"}>
              Current
            </option>
            {versions.map((version, index) => (
              <option key={index} value={version}>
                {version}
              </option>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl mt={4}>
        <FormLabel>Survey Title</FormLabel>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter survey title"
        />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>Survey Instruction</FormLabel>
        <Input
          value={instruction}
          onChange={(e) => setInstruction(e.target.value)}
          placeholder="Enter survey instruction"
        />
      </FormControl>
      <FormControl id="survey-image" mt={4}>
        <FormLabel>Survey Image</FormLabel>
        <Input type="file" onChange={handleImageChange} />
        <img style={{maxHeight: "200px", marginTop: "20px"}} src={image}></img>
      </FormControl>
      {/* <FormControl id="survey-end-time" mt={4}>
        <FormLabel>Survey Expiry Time</FormLabel>
        <DateTimePicker
          value={endTime}
          onChange={handleEndTimeChange}
          format={"dd/MM/yyyy HH:mm"}
        />
      </FormControl> */}
      <FormControl id="survey-timer" mt={4}>
        <FormLabel>Timer (in seconds, 0 for unlimited time)</FormLabel>
        <NumberInput
          value={timer}
          onChange={(value) => setTimer(value)}
          min={0}
          precision={0}
          step={10}
        >
          <NumberInputField placeholder="Enter timer" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
    </Box>
  );
};

export default SurveyInformation;
