import React from 'react';

import { Box, VStack, Flex, FormControl, FormLabel, Input, Button, Divider, Text} from '@chakra-ui/react';

const ExpressionEditor = ({expressions, handleExpressionNameChange, handleExpressionChange, handleDeleteExpression}) => {
    return (
        <Box
            bg="gray.100"
            borderRadius="md"
            p={4}
            mt={2}
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
        >
            <VStack spacing={4} alignItems="start">
                {expressions.length > 0 ?
                    (expressions.map((expressionObj, index) => (
                        <>
                            <Flex alignItems="center" key={index} width="100%">
                                <FormControl width="30%">
                                    <FormLabel>Expression Name</FormLabel>
                                    <Input
                                        value={expressionObj.name}
                                        onChange={(e) => handleExpressionNameChange(e.target.value, index)}
                                        placeholder="Enter expression name"
                                    />
                                </FormControl>
                                <FormControl ml={4} flexGrow={1}>
                                    <FormLabel>Expression</FormLabel>
                                    <Input
                                        value={expressionObj.expression}
                                        onChange={(e) => handleExpressionChange(e.target.value, index)}
                                        placeholder="Enter expression"
                                    />
                                </FormControl>
                                <Button ml={4} onClick={() => handleDeleteExpression(index)} colorScheme="red">
                                    Delete
                                </Button>
                            </Flex>
                            {index < expressions.length - 1 && (
                                <Divider mt={2} mb={2} width="100%" borderColor="gray.300" />
                            )}
                        </>
                    )))
                    : (<Text fontStyle="italic" color="gray.600">
                        No expressions yet.
                    </Text>)}
            </VStack>
        </Box>
    )
}

export default ExpressionEditor