import React, { useState, useEffect } from 'react';
import { Flex, Box, Stat, StatLabel, StatNumber, StatHelpText, StatArrow, StatGroup } from '@chakra-ui/react';

import SurveyService from '../../services/survey.service';

const HomePage = () => {
  const [stats, setStats] = useState({
    totalSurveys: '',
    notApprovedSurveys: '',
    totalLinks: '',
    totalResponses: '',
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const data = await SurveyService.getStatistics();
        setStats(data);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };

    fetchStats();
  }, []);

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <StatGroup>
        <Stat>
          <StatLabel>Total Surveys</StatLabel>
          <StatNumber>{stats.totalSurveys}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Not Approved Surveys</StatLabel>
          <StatNumber>{stats.notApprovedSurveys}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Total Links</StatLabel>
          <StatNumber>{stats.totalLinks}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Total Responses</StatLabel>
          <StatNumber>{stats.totalResponses}</StatNumber>
        </Stat>
      </StatGroup>
    </Flex>
  );
};

export default HomePage;
