// SurveyTable.js
import React, { useState, useEffect } from "react";
import {
    Flex,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Center,
    Input,
    InputGroup,
    InputLeftElement,
    IconButton,
    InputRightElement,
    Tooltip,
    HStack,
    VStack,
    useToast
} from "@chakra-ui/react";
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { FaCheckCircle, FaTimesCircle, FaSearch, FaTimes, } from 'react-icons/fa';

import surveyService from '../../services/survey.service';

const SurveyTable = () => {
    const [surveys, setSurveys] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);
    const toast = useToast();

    useEffect(() => {
        fetchSurveys();
    }, []);

    const fetchSurveys = async () => {
        setIsLoading(true);
        const response = await surveyService.getAllSurveyList();
        setSurveys(response);
        setIsLoading(false);
    };

    const editSurvey = (id) => {
        navigate(`/admin/survey/${id}/edit`);
    };

    const onAddClick = () => {
        navigate('/admin/survey/new');
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const clearSearchTerm = () => {
        setSearchTerm("");
    };

    const filteredSurveys = surveys.filter((survey) =>
        survey.schema.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const deleteSurvey = async (id) => {
        if (window.confirm("Are you sure you want to delete this survey?")) {
            try {
                await surveyService.deleteSurvey(id);
                // Remove the deleted survey from the state
                setSurveys(surveys.filter((survey) => survey._id !== id));
            } catch (error) {
                console.error("Failed to delete survey:", error);
            }
        }
    };

    const approveSurvey = async (id, isApproved) => {
        try {
            await surveyService.approveSurvey(id, isApproved);
            await fetchSurveys();
        } catch (error) {
            console.error("Failed to update survey:", error);
        }
    }

    const unlockSurvey = async (id) => {
        try {
            const result = await surveyService.unlockSurvey(id);
            if (result === false) {
                toast({
                    title: 'There are outstanding surveys. Unlock is not available at this moment.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right',
                });
            } else {
                await fetchSurveys();
            }
        } catch (error) {
            console.error("Failed to unlock survey:", error);
        }
    }

    const ifSurveyExpired = (surveyTime) => {
        const currentTime = DateTime.local();
        const surveyEndTime = DateTime.fromISO(surveyTime);
        return currentTime > surveyEndTime;
    };

    const expireNow = async (id) => {
        try {
            const currentTime = DateTime.local().toISO();
            await surveyService.updateSurvey(id, { endTime: currentTime });
            await fetchSurveys();
        } catch (error) {
            console.error("Failed to expire survey:", error);
        }
    };


    return (
        <>
            {isLoading ? (
                <Center w="100%" h="100vh">
                    <ReactLoading type="bars" color="rgb(130 229 209)" height="5%" width="5%" />
                </Center>
            ) : (

                <Box>
                    <Flex mb={4} mr={12} justifyContent="flex-end">
                        <InputGroup mr={4}>
                            <InputLeftElement
                                pointerEvents="none"
                                children={<FaSearch color="gray.300" />}
                            />
                            <Input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <InputRightElement>
                                <IconButton
                                    aria-label="Clear search"
                                    icon={<FaTimes />}
                                    onClick={clearSearchTerm}
                                    isRound
                                    size="sm"
                                    display={searchTerm ? "inline-flex" : "none"}
                                />
                            </InputRightElement>
                        </InputGroup>
                        <Button onClick={onAddClick} colorScheme="teal">
                            Add
                        </Button>
                    </Flex>

                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Title</Th>
                                {/* <Th>Expiry Time</Th> */}
                                <Th>Approved</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {filteredSurveys.map((survey) => (
                                <Tr key={survey._id}>

                                    <Td>{survey.schema.title}</Td>
                                    {/* <Td style={ifSurveyExpired(survey.endTime) ? { color: "red" } : { color: "black" }}>{DateTime.fromISO(survey.endTime).toFormat('yyyy-MM-dd HH:mm')}</Td> */}
                                    <Td textAlign={"center"}>{survey.isApproved ? <FaCheckCircle /> : <FaTimesCircle />}</Td>
                                    <Td>
                                        <HStack spacing={2} align="" mb={2}>
                                            {user.roles.includes('ROLE_ADMIN') &&
                                                <>
                                                    <Button colorScheme="teal" variant="outline" onClick={() => approveSurvey(survey._id, !survey.isApproved)}>
                                                        {survey.isApproved ? "Disapprove" : "Approve"}
                                                    </Button>
                                                    <Button colorScheme="teal" variant="outline" isDisabled={survey.isEditable} onClick={() => unlockSurvey(survey._id)}>
                                                        Unlock
                                                    </Button>
                                                </>
                                            }
                                            <Button colorScheme="teal" variant="outline" onClick={() => navigate('/admin/survey/' + survey._id + '/responses', { state: { surveyTitle: survey.schema.title } })}>
                                                View Responses
                                            </Button>
                                            <Tooltip label="This survey is locked. Please ask administrator to unlock." isDisabled={survey.isEditable}>
                                                <Button colorScheme="teal" variant="outline" isDisabled={!survey.isEditable} onClick={() => editSurvey(survey._id)}>
                                                    Edit
                                                </Button>
                                            </Tooltip>
                                        </HStack>
                                        {/* <HStack spacing={2} align="start">
                                            
                                            <Button
                                                colorScheme="yellow"
                                                mr={4}
                                                variant="outline"
                                                onClick={() => expireNow(survey._id)}
                                                isDisabled={ifSurveyExpired(survey.endTime)}
                                            >
                                                Expire Now
                                            </Button>
                                        </HStack> */}

                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            )
            }
        </>
    );
};

export default SurveyTable;
