import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';

import SurveyLinkListTable from './SurveyLinkListTable';
import surveyLinksService from '../../services/surveylink.service';
import surveyService from '../../services/survey.service';

function SurveyLinkManagment() {

  const [surveyLinks, setSurveyLinks] = useState();
  const [surveyOptions, setSurveyOptions] = useState([]);

  useEffect(() => {
    fetchSurveyLinks();
  }, []);

  const fetchSurveyLinks = async () => {
    var response = await surveyLinksService.getSurveyLinks();
    response = response.map(r=> ({
      ...r,
      surveys: r.surveys.map(e => ({
        _id: e._id,
        title: e.schema.title
      }))
    }));
    setSurveyLinks(response);
  };

  useEffect(() => {
    fetchSurveyOptions();
  }, []);

  const fetchSurveyOptions = async () => {
    const response = await surveyService.getSurveyList();
    const options = response.map((survey) => ({
      value: survey._id,
      label: survey.schema.title
    }));
    setSurveyOptions(options);
  };

  const onSubmit = async () => {
    await fetchSurveyLinks();
  }

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <SurveyLinkListTable surveyLinks={surveyLinks} surveyOptions={surveyOptions} onSubmit={onSubmit} />
    </Flex>
  );
}

export default SurveyLinkManagment;
