import axios from 'axios';

const axiosInstance = axios.create({ baseURL: process.env.NODE_ENV !== 'production' ? 'http://127.0.0.1:8181/api' : 'https://hkqaa-project-api.ksolutionsz.com/api', withCredentials: true });

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401 && error.config.url !== '/auth/signin' && error.config.url !== '/auth/verifytoken') {
      await axiosInstance
        .post('/auth/refreshtoken', {
          withCredentials: true,
        })
        .catch((err) => Promise.reject(err));
      console.log(error.config);
      return axios(error.config);
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
