import React, { useEffect, useState } from 'react';
import {
  Center, Flex, Input, Text, VStack, Button,
} from '@chakra-ui/react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactLoading from 'react-loading';

import publicService from '../../services/public.service';
import { setEmail, setSurveyList } from '../../slices/public';

export default function PublicLogin() {
  const [firstNameText, setFirstNameText] = useState('');
  const [lastNameText, setLastNameText] = useState('');
  const [emailText, setEmailText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isInvalid, setIsInvalid] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginAction = (result) => {
    setIsLoading(false);
    dispatch(setEmail(result.email));
    dispatch(setSurveyList(result.surveys));
    navigate('/public/list');
  };

  const autoLogin = async () => {
    try {
      if (searchParams.get('token') !== null) {
        localStorage.removeItem('email');
        localStorage.removeItem('link');
        setIsInvalid(false);
        setIsLoading(false);
      } else {
        const result = await publicService.publicLogin(localStorage.getItem('email'), localStorage.getItem('link'), firstNameText, lastNameText);
        loginAction(result);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const login = async () => {
    try {
      if (searchParams.get('token') === null) {
        setIsInvalid(true);
        return;
      }
      const result = await publicService.publicLogin(emailText, searchParams.get('token'), firstNameText, lastNameText);
      loginAction(result);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      alert(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    autoLogin();
  }, []);

  if (isLoading) {
    return (
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Center w="100%" h="100vh">
          <ReactLoading type="bars" color="rgb(130 229 209)" height="5%" width="5%" />
        </Center>
      </Flex>
    );
  }
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Center h="100%" w="100%">
        {isInvalid
          ? <Text>The link is invalid.</Text>
          : (
            <VStack w="50%" spacing={5}>
              <Text>First Name</Text>
              <Input size="lg" value={firstNameText} onChange={(e) => setFirstNameText(e.target.value)} />
              <Text>Last Name</Text>
              <Input size="lg" value={lastNameText} onChange={(e) => setLastNameText(e.target.value)} />
              <Text>Email Address</Text>
              <Input size="lg" value={emailText} onChange={(e) => setEmailText(e.target.value)} />
              <Button onClick={login}>Login</Button>
            </VStack>
          )}
      </Center>
    </Flex>
  );
}
