import React, { useState, useEffect } from "react";
import {
    Flex,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Center,
    Input,
    InputGroup,
    InputLeftElement,
    IconButton,
    InputRightElement,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Stack
} from "@chakra-ui/react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { DateTime } from 'luxon';
import ReactLoading from 'react-loading';
import QRCode from 'qrcode.react';

import surveyLinksService from '../../services/surveylink.service';
import SurveyLinkModal from './SurveyLinkModal';
import DateRangeFilter from '../../components/DateRangeFilter';

const SurveyLinksTable = ({ surveyLinks, surveyOptions, onSubmit }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSurveyLink, setCurrentSurveyLink] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const [searchTerm, setSearchTerm] = useState("");
    const [dateRange, setDateRange] = useState({ start: null, end: null });
    const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
    const [currentQRCodeLink, setCurrentQRCodeLink] = useState("");
    const [expandedRows, setExpandedRows] = useState([]);

    const handleDateRangeChange = (start, end) => {
        setDateRange({ start, end });
    };

    const handleAddClick = () => {
        const newDefaultEndTime = new Date();
        newDefaultEndTime.setMonth(newDefaultEndTime.getMonth() + 1);
        newDefaultEndTime.setHours(0);
        newDefaultEndTime.setMinutes(0);
        newDefaultEndTime.setSeconds(0);
        newDefaultEndTime.setMilliseconds(0);
        setCurrentSurveyLink({ title: "", surveys: [], emails: [], endTime: newDefaultEndTime });
        setIsModalOpen(true);
    };

    const handleEditClick = (surveyLink) => {
        setCurrentSurveyLink({ ...surveyLink, endTime: DateTime.fromISO(surveyLink.endTime).toJSDate() });
        setIsModalOpen(true);
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const handleSort = () => {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    const sortedSurveyLinks = surveyLinks
        ? [...surveyLinks].sort((a, b) => {
            const dateA = new Date(a.endTime);
            const dateB = new Date(b.endTime);
            return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        })
        : [];

    const handleModalSubmit = async (surveyLink) => {
        try {
            if (surveyLink._id) {
                await surveyLinksService.updateSurveyLink(surveyLink._id, surveyLink);
            } else {
                await surveyLinksService.createSurveyLink(surveyLink);
            }
            onSubmit();
            handleClose();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteClick = async (id) => {
        if (window.confirm("Are you sure you want to delete this survey link?")) {
            try {
                await surveyLinksService.deleteSurveyLink(id);
                onSubmit();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const clearSearchTerm = () => {
        setSearchTerm("");
    };

    const ifSurveyExpired = (surveyTime) => {
        const currentTime = DateTime.local();
        const surveyEndTime = DateTime.fromISO(surveyTime);
        return currentTime > surveyEndTime;
    };

    const filteredSurveyLinks = sortedSurveyLinks.filter((surveyLink) => {
        const endTime = DateTime.fromISO(surveyLink.endTime);
        const searchFilter = searchTerm
            ? surveyLink.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            surveyLink.emails.some((email) =>
                email.toLowerCase().includes(searchTerm.toLowerCase())
            )
            : true;

        const dateRangeFilter = (() => {
            if (dateRange.start && endTime < DateTime.fromISO(dateRange.start)) {
                return false;
            }
            if (dateRange.end && endTime > DateTime.fromISO(dateRange.end)) {
                return false;
            }
            return true;
        })();

        return searchFilter && dateRangeFilter;
    });

    const handleOpenQRCodeModal = (link) => {
        setCurrentQRCodeLink(link);
        setIsQRCodeModalOpen(true);
    };

    const handleCloseQRCodeModal = () => {
        setIsQRCodeModalOpen(false);
    };

    const toggleRowExpansion = (rowId) => {
        if (expandedRows.includes(rowId)) {
            setExpandedRows(expandedRows.filter((id) => id !== rowId));
        } else {
            setExpandedRows([...expandedRows, rowId]);
        }
    };

    const toggleLink = async (surveyLink) => {
        try {
            await surveyLinksService.updateSurveyLink(surveyLink._id, { ...surveyLink, isStarted: !surveyLink.isStarted });
            onSubmit();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {!surveyLinks ? (
                <Center w="100%" h="100vh">
                    <ReactLoading type="bars" color="rgb(130 229 209)" height="5%" width="5%" />
                </Center>
            ) : (
                <Box>
                    <Flex mb={4} mr={12} justifyContent="flex-end">
                        <InputGroup mr={4}>
                            <InputLeftElement
                                pointerEvents="none"
                                children={<FaSearch color="gray.300" />}
                            />
                            <Input
                                type="text"
                                placeholder="Search title or email..."
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <InputRightElement>
                                <IconButton
                                    aria-label="Clear search"
                                    icon={<FaTimes />}
                                    onClick={clearSearchTerm}
                                    isRound
                                    size="sm"
                                    display={searchTerm ? "inline-flex" : "none"}
                                />
                            </InputRightElement>
                        </InputGroup>
                        <Button onClick={handleAddClick} colorScheme="teal">
                            Add
                        </Button>
                    </Flex>
                    <Box mr={4} mb={4}>
                        <DateRangeFilter onChange={handleDateRangeChange} />
                    </Box>

                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th style={{ width: '100px', wordWrap: 'break-word' }}>Title</Th>
                                <Th style={{ width: '280px', wordWrap: 'break-word' }}>Surveys</Th>
                                <Th>Email</Th>
                                <Th cursor="pointer" onClick={handleSort}>
                                    Expiry Time {sortOrder === "asc" ? "▲" : "▼"}
                                </Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {filteredSurveyLinks.map((surveyLink) => (
                                <Tr key={surveyLink._id}>
                                    <Td style={{ width: '100px', wordWrap: 'break-word' }}>{surveyLink.title}</Td>
                                    <Td style={{ width: '280px', wordWrap: 'break-word' }}>{surveyLink.surveys.map((e, i) => (<>{i + 1}. {e.title}<br /></>))}</Td>
                                    <Td>
                                        {surveyLink.emails.slice(0, 5).map((e) => (
                                            <React.Fragment key={e}>
                                                {e} ({surveyLink.responsesCount[e] ?? 0}/{surveyLink.surveys.length})
                                                <br />
                                            </React.Fragment>
                                        ))}
                                        {surveyLink.emails.length > 5 && !expandedRows.includes(surveyLink._id) && (
                                            <Text
                                                as="button"
                                                color="blue.500"
                                                onClick={() => toggleRowExpansion(surveyLink._id)}
                                            >
                                                More
                                            </Text>
                                        )}
                                        {expandedRows.includes(surveyLink._id) && (
                                            <>
                                                {surveyLink.emails.slice(5).map((e) => (
                                                    <React.Fragment key={e}>
                                                        {e} ({surveyLink.responsesCount[e] ?? 0}/{surveyLink.surveys.length})
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                                <Text
                                                    as="button"
                                                    color="blue.500"
                                                    onClick={() => toggleRowExpansion(surveyLink._id)}
                                                >
                                                    Less
                                                </Text>
                                            </>
                                        )}
                                    </Td>

                                    <Td style={ifSurveyExpired(surveyLink.endTime) ? { color: "red" } : { color: "black" }}>{DateTime.fromISO(surveyLink.endTime).toFormat('yyyy-MM-dd HH:mm')}</Td>
                                    <Td>
                                        <Stack direction="row" spacing={2} mb={2}>
                                            <Button colorScheme="teal" variant="outline" onClick={() => toggleLink(surveyLink)}>
                                                {surveyLink.isStarted ? "Stop" : "Start"}
                                            </Button>
                                            <Button isDisabled={!surveyLink.isStarted} colorScheme="teal" variant="outline" onClick={() => navigator.clipboard.writeText(`${window.location.hostname}/public?token=${surveyLink._id}`)}>
                                                Copy Link
                                            </Button>
                                            <Button
                                                colorScheme="teal"
                                                variant="outline"
                                                isDisabled={!surveyLink.isStarted}
                                                onClick={() =>
                                                    handleOpenQRCodeModal(
                                                        `https://${window.location.hostname}/public?token=${surveyLink._id}`
                                                    )
                                                }
                                            >
                                                QR Code
                                            </Button>
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <Button isDisabled={surveyLink.isStarted} colorScheme="teal" variant="outline" onClick={() => handleEditClick(surveyLink)}>
                                                Edit
                                            </Button>
                                            <Button isDisabled={surveyLink.isStarted} colorScheme="red" onClick={() => handleDeleteClick(surveyLink._id)}>
                                                Delete
                                            </Button>
                                        </Stack>
                                    </Td>

                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    <SurveyLinkModal
                        isOpen={isModalOpen}
                        onClose={handleClose}
                        onSubmit={handleModalSubmit}
                        surveyOptions={surveyOptions}
                        currentSurveyLink={currentSurveyLink}
                    />
                    <Modal isOpen={isQRCodeModalOpen} onClose={handleCloseQRCodeModal}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>QR Code</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Center>
                                    <QRCode
                                        value={currentQRCodeLink}
                                        size={128}
                                        level={"L"}
                                        includeMargin={true}
                                    />
                                </Center>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="teal" mr={3} onClick={handleCloseQRCodeModal}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Box>
            )}
        </>
    );
};

export default SurveyLinksTable;
