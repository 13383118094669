import React, { useState } from 'react';
import { Flex, Center, useDisclosure, Button } from '@chakra-ui/react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';

import 'survey-core/defaultV2.min.css';
import './style.css';

import surveyService from '../../services/survey.service';
import publicService from '../../services/public.service';
import ErrorMessageDialog from '../../components/AlertDialog';

export default function SurveyView() {
  const [surveySchema, setSurveySchema] = useState({});
  const [startTime, setStartTime] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState('');
  const [searchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { email } = useSelector((state) => state.public);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nextSurvey, setNextSurvey] = useState('');

  useState(() => {
    const getData = async () => {
      try {
        const serverSchema = await surveyService.getSurveySchema(searchParams.get('id'));
        setSurveySchema(serverSchema);
      } catch (err) {
        console.error(err);
        setErrorMessage(err.message);
        onOpen();
      }
    };
    getData();
  });

  if (email === '') {
    return (<Navigate to="/public/list" />);
  }

  if (Object.keys(surveySchema).length === 0) {
    return (
      <Center w="100%" h="100vh">
        <ErrorMessageDialog isOpen={isOpen} onOpen={onOpen} onClose={onClose} message={errorMessage} />
        <ReactLoading type="bars" color="rgb(130 229 209)" height="5%" width="5%" />
      </Center>
    );
  }
  const survey = new Model(surveySchema);

  survey.onComplete.add(async (sender, options) => {
    console.log(sender);
    const surveyId = searchParams.get('id');
    setIsLoading(true);
    try {
      const result = await publicService.submitResponse(email, localStorage.getItem('firstName'), localStorage.getItem('lastName'),  startTime, new Date(), surveyId, sender.data, localStorage.getItem('link'));
      setIsCompleted(true);
      setNextSurvey(result.nextSurvey);
    } catch (err) {
      alert('Error occurs when saving response. Please retry.');
      navigate('/public/list');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  });

  const handleNextSurvey = async () => {
    setIsCompleted(false);
    navigate(`/public/survey?id=${nextSurvey}`);
    setIsLoading(true);
    const serverSchema = await surveyService.getSurveySchema(nextSurvey);
    setSurveySchema(serverSchema);
    setIsLoading(false);
  };

  const navigateToHome = () => {
    navigate(`/public/list`);
  }

  const customCss = {
    "header": "sd-title custom-header"
  };
  
  survey.css = customCss;
  if (isLoading) {
    return (
      <Center w="100%" h="100vh">
        <ReactLoading type="bars" color="rgb(130 229 209)" height="5%" width="5%" />
      </Center>
    )
  }

  return (
    <Flex direction="column" >
      <ErrorMessageDialog isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      {!isCompleted ?
        <Survey model={survey} />
        :
        (
          <>
            <div class="sv-components-column sv-components-column--expandable sd-body sd-completedpage">
              <h1 style={{ fontSize: '28px' }}>Thank you for completing the survey!</h1>
            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>
              {nextSurvey ?
                <Button style={{ maxWidth: '120px', }} onClick={handleNextSurvey}>Next Survey</Button>
                :
                <Button style={{ maxWidth: '120px', }} onClick={navigateToHome}>Home</Button>
              }
            </div>
          </>

        )
      }
    </Flex >
  );
}
