import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import SurveyListTable from './SurveyListTable';

function SurveyBuilderMain() {

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <SurveyListTable />
    </Flex>
  );
}

export default SurveyBuilderMain;
