import axiosInstance from './axiosIntance';

const getAllUsers = async (id) => {
    try {
        const result = await axiosInstance.get(`/user`);
        return result.data;
    } catch (err) {
        console.error(err);
        throw (err.response.data.error);
    }
};

const createUser = async (username, email, password, roles) => {
    try {
        const result = await axiosInstance.post(`/user`, {
            username,
            email,
            password,
            roles,
        });
        return result.data;
    } catch (err) {
        console.error(err);
        if (err.response.data.message) throw err.response.data.message;
        throw err.response.data.error;
    }
}

const updateUserDetails = async (id, updatedEmail, updatedPassword, updatedRoles) => {
    try {
        const result = await axiosInstance.put(`/user/${id}`, {
            email: updatedEmail,
            password: updatedPassword,
            roles: updatedRoles,
        });
        return result.data;
    } catch (err) {
        console.error(err);
        if (err.response.data.message) throw err.response.data.message;
        throw err.response.data.error;
    }
};

const deleteUser = async (id) => {
    try {
        const result = await axiosInstance.delete(`/user/${id}`);
        return result.data;
    } catch (err) {
        console.error(err);
        if (err.response.data.message) throw err.response.data.message;
        throw err.response.data.error;
    }
};

const functions = {
    getAllUsers,
    updateUserDetails,
    createUser,
    deleteUser,
};

export default functions;
