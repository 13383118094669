import { Flex, Text, Input } from '@chakra-ui/react';

const DateRangeFilter = ({ onChange }) => {
  const handleStartChange = (e) => {
    onChange(e.target.value, null);
  };

  const handleEndChange = (e) => {
    onChange(null, e.target.value);
  };

  return (
    <Flex alignItems="center">
      <Input type="datetime-local" onChange={handleStartChange} />
      <Text mx={2}>to</Text>
      <Input type="datetime-local" onChange={handleEndChange} />
    </Flex>
  );
};

export default DateRangeFilter;