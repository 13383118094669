import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { Center } from '@chakra-ui/react';
import { verifyToken } from '../slices/auth';

export default function Loading() {
  const dispatch = useDispatch();
  const { isLoading, isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(verifyToken());
  }, [dispatch]);

  if (isLoggedIn && isLoading === false) {
    return <Navigate to="/admin/home" />;
  } if (!isLoggedIn && isLoading === false) {
    return <Navigate to="/admin/login" />;
  }
  return (
    <Center w="100%" h="100vh">
      <ReactLoading type="bars" color="rgb(130 229 209)" height="5%" width="5%" />
    </Center>
  );
}
