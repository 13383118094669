import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

export default function ErrorMessageDialog(props) {
  const {
    isOpen, onClose, message,
  } = props;
  const cancelRef = React.useRef();
  const navigate = useNavigate();

  const backButtonClicked = () => {
    onClose();
    navigate('/public');
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={backButtonClicked}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Error
          </AlertDialogHeader>
          <AlertDialogBody>
            {message}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={backButtonClicked}>
              Back
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

ErrorMessageDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  message: PropTypes.string,
};

ErrorMessageDialog.defaultProps = {
  isOpen: false,
  onClose: () => {},
  message: '',
};
