import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';

import AuthService from '../services/auth.service';

export const register = createAsyncThunk(
  'auth/register',
  async ({ username, email, password }, thunkAPI) => {
    try {
      const response = await AuthService.register(username, email, password);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message = (error.response
        && error.response.data
        && error.response.data.message)
        || error.message
        || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const login = createAsyncThunk(
  'auth/login',
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(username, password);
      return { user: data };
    } catch (error) {
      const message = (error.response
        && error.response.data
        && error.response.data.message)
        || error.message
        || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const verifyToken = createAsyncThunk(
  'auth/verifyToken',
  async (_, thunkAPI) => {
    try {
      const data = await AuthService.verifyToken();
      return { user: data };
    } catch (error) {
      const message = (error.response
        && error.response.data
        && error.response.data.message)
        || error.message
        || error.toString();
      if (error.response.status !== 403) {
        thunkAPI.dispatch(setMessage(message));
      }
      return thunkAPI.rejectWithValue();
    }
  },
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await AuthService.logout();
});

const initialState = { isLoggedIn: false, user: null, isLoading: true };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(register.fulfilled, (state, action) => {
        state.isLoggedIn = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoggedIn = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(verifyToken.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(verifyToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(verifyToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
      });
  },
  // extraReducers: {
  //     [register.fulfilled]: (state, action) => {
  //         state.isLoggedIn = false;
  //     },
  //     [register.rejected]: (state, action) => {
  //         state.isLoggedIn = false;
  //     },
  //     [login.fulfilled]: (state, action) => {
  //         state.isLoggedIn = true;
  //         state.user = action.payload.user;
  //     },
  //     [login.rejected]: (state, action) => {
  //         state.isLoggedIn = false;
  //         state.user = null;
  //     },
  //     [logout.fulfilled]: (state, action) => {
  //         state.isLoggedIn = false;
  //         state.user = null;
  //     },
  // },
});

const { reducer } = authSlice;
export default reducer;
