import { createSlice } from '@reduxjs/toolkit';

const initialState = { email: '', surveyList: [] };

const messageSlice = createSlice({
  name: 'public',
  initialState,
  reducers: {
    setEmail: (state, action) => ({ ...state, email: action.payload }),
    clearEmail: (state) => ({ ...state, email: '' }),
    setSurveyList: (state, action) => ({ ...state, surveyList: action.payload }),
    clearSurveyList: (state) => ({ ...state, surveyList: [] }),
  },
});

const { reducer, actions } = messageSlice;

export const {
  setEmail, clearEmail, setSurveyList, clearSurveyList,
} = actions;
export default reducer;
