import React, { useState, useEffect } from 'react';
import {
   Text, Flex, Divider, Heading, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Box, Button, Tooltip, IconButton, useDisclosure
} from '@chakra-ui/react';
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useNavigate } from 'react-router-dom';
import surveyService from '../../services/survey.service';
import { SurveyInformation, PagesManagement, ExpressionEditor } from './components/SurveySchemaCreator';
import QuestionEditor from './QuestionEditor';

const SurveySchemaCreator = ({ onSchemaCreated, survey }) => {

    const navigate = useNavigate();
    const [pages, setPages] = useState([]);
    const [selectedPage, setSelectedPage] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [title, setTitle] = useState('');
    const [instruction, setInstruction] = useState('');
    const [image, setImage] = useState('');
    const [version, setVersion] = useState(0);
    const [selectedVersion, setSelectedVersion] = useState();
    const [timer, setTimer] = useState(0);
    const [expressions, setExpressions] = useState([]);
    const [overallExpressions, setOverallExpressions] = useState([]);
    const [allowedTypes, setAllowedTypes] = useState(['text', 'multipleChoice']);

    const now = new Date();
    now.setSeconds(0);
    now.setMilliseconds(0);
    const defaultEndTime = new Date();
    defaultEndTime.setMonth(defaultEndTime.getMonth() + 1);
    defaultEndTime.setMinutes(0);
    defaultEndTime.setSeconds(0);
    defaultEndTime.setMilliseconds(0);
    const [endTime, setEndTime] = useState(defaultEndTime);

    const [editingQuestionIndex, setEditingQuestionIndex] = useState(-1);

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (survey) {
            const pages = convertApiJsonToQuestions(survey.schema);
            const { questions, surveyTimer, expressions, overallExpressions } = pages[0];
            setTitle(survey.schema.title);
            setInstruction(survey.schema.description);
            setImage(survey.schema.logo);
            setVersion(survey.schema.version);
            setEndTime(new Date(survey.endTime));
            setPages(pages);
            setQuestions(questions);
            setTimer(surveyTimer);
            setExpressions(expressions);
            setOverallExpressions(overallExpressions);
        } else {
            setTitle('');
            setInstruction('');
            setImage('');
            setVersion(0);
            setEndTime(defaultEndTime);
            setPages([{ title: `Page 1`, questions: [], questionOrder: 'initial' }]);
            setQuestions([]);
            setTimer(0);
            setExpressions([]);
            setOverallExpressions([]);
        }
    }, [survey]);

    const convertApiJsonToQuestions = (surveyData) => {
        var pages = [];
        for (var page of surveyData.pages) {
            const surveyTimer = surveyData.maxTimeToFinish ? surveyData.maxTimeToFinish : 0;

            const questions = page.elements.map((element) => {
                const question = {
                    name: element.name,
                    title: element.title,
                    type: element.type === 'radiogroup' ? 'multipleChoice' : element.type,
                };

                if (element.type === 'radiogroup') {
                    question.options = element.choices.map((choice) => ({ value: choice.value, text: choice.text }));
                }

                return question;
            });

            const expressions = surveyData.calculatedValues
                ? surveyData.calculatedValues.map((calculatedValue) => ({
                    name: calculatedValue.name,
                    expression: calculatedValue.expression,
                }))
                : [];
            const overallExpressions = surveyData.overallExpressions
                ? surveyData.overallExpressions.map((expression) => ({
                    name: expression.name,
                    expression: expression.expression,
                }))
                : [];
            const questionsOrder = page.questionsOrder === 'random' ? 'random' : 'initial'
            pages.push({ questions, surveyTimer, expressions, overallExpressions, questionsOrder, randomNumber: page.randomNumber });
        }
        return pages;
    };

    const handleAddOrUpdateQuestionAndClose = (question) => {
        handleAddOrUpdateQuestion(question);
        onClose();
    };

    const handleAddOrUpdateQuestion = (question) => {
        if (editingQuestionIndex >= 0) {
            const updatedQuestions = [...questions];
            updatedQuestions[editingQuestionIndex] = question;
            setQuestions(updatedQuestions);
            setEditingQuestionIndex(-1);
            let tempPages = [...pages];
            let page = {
                ...tempPages[selectedPage],
                questions: updatedQuestions
            }
            tempPages[selectedPage] = page;
            setPages(tempPages);
        } else {
            setQuestions([...questions, question]);
            let tempPages = [...pages];
            let page = {
                ...tempPages[selectedPage],
                questions: [...questions, question]
            }
            tempPages[selectedPage] = page;
            setPages(tempPages);
        }
    };

    const handleAddQuestionClick = () => {
        setEditingQuestionIndex(-1);
        setAllowedTypes(['text', 'multipleChoice']);
        onOpen();
    };

    const handleEditQuestion = (index) => {
        setEditingQuestionIndex(index);
        onOpen();
    };

    const handleDeleteQuestion = (index) => {
        setQuestions(questions.filter((_, i) => i !== index));
        let tempPages = [...pages];
        let page = {
            ...tempPages[selectedPage],
            questions: questions.filter((_, i) => i !== index)
        }
        tempPages[selectedPage] = page;
        setPages(tempPages);
    };

    const handleEndTimeChange = (date) => {
        setEndTime(date);
    };

    const handleSave = () => {
        if (!validateExpressions()) {
            alert('All expressions must have an expression value.');
            return;
        }

        var validRandomNumber = true;
        for (var page of pages) {
            if (page.randomNumber > page.questions.length) {
                validRandomNumber = false;
            }
        }

        if (!validRandomNumber) {
            return;
        }

        const convertedPages = pages.map((page, index) => {
            const surveyQuestions = page.questions.map((question) => {
                let surveyQuestion = {
                    name: question.name,
                    title: question.title,
                    type: question.type === 'multipleChoice' ? 'radiogroup' : question.type,
                };

                if (question.type === 'multipleChoice') {
                    surveyQuestion.choices = question.options;
                }

                return surveyQuestion;
            });
            return {
                name: `page${index + 1}`,
                questionsOrder: page.questionsOrder,
                randomNumber: page.randomNumber,
                elements: surveyQuestions
            }
        });

        var surveySchema = {
            title,
            description: instruction,
            logo: image,
            logoFit: "cover",
            logoPosition: "bottom",
            pages: convertedPages,
            version: version,
        };

        if (expressions.length > 0) {
            surveySchema.calculatedValues = expressions.map((expressionObj) => ({
                name: expressionObj.name,
                expression: expressionObj.expression,
                includeIntoResult: true
            }));
        }

        if (overallExpressions.length > 0) {
            surveySchema.overallExpressions = overallExpressions.map((expressionObj) => ({
                name: expressionObj.name,
                expression: expressionObj.expression,
            }));
        }

        if (parseInt(timer) && parseInt(timer) !== 0) {
            surveySchema = {
                ...surveySchema,
                showTimerPanel: "top",
                maxTimeToFinish: timer,
            }
        } else {
            delete surveySchema.showTimerPanel;
            delete surveySchema.maxTimeToFinish;
        }
        const saveObj = {
            title,
            schema: surveySchema,
            // endTime: endTime.toISOString(),
        };
        onSchemaCreated(saveObj);
    };

    const handleExpressionNameChange = (value, index) => {
        const updatedExpressions = [...expressions];
        updatedExpressions[index].name = value;
        setExpressions(updatedExpressions);
    };

    const handleExpressionChange = (value, index) => {
        const updatedExpressions = [...expressions];
        updatedExpressions[index].expression = value;
        setExpressions(updatedExpressions);
    };

    const handleDeleteExpression = (index) => {
        const updatedExpressions = [...expressions];
        updatedExpressions.splice(index, 1);
        setExpressions(updatedExpressions);
    };

    const handleAddExpression = () => {
        setExpressions([...expressions, { name: '', expression: '' }]);
    };

    const validateExpressions = () => {
        return expressions.every((expression) => expression.expression.trim() !== '');
    };

    const handleOverallExpressionNameChange = (value, index) => {
        const updatedExpressions = [...overallExpressions];
        updatedExpressions[index].name = value;
        setOverallExpressions(updatedExpressions);
    };

    const handleOverallExpressionChange = (value, index) => {
        const updatedExpressions = [...overallExpressions];
        updatedExpressions[index].expression = value;
        setOverallExpressions(updatedExpressions);
    };

    const handleAddOverallExpression = () => {
        setOverallExpressions([...overallExpressions, { name: "", expression: "" }]);
    };

    const handleDeleteOverallExpression = (index) => {
        const updatedExpressions = overallExpressions.filter((_, i) => i !== index);
        setOverallExpressions(updatedExpressions);
    };

    const fetchPreviousVersion = async (version) => {
        var title, instruction, image, questions, surveyTimer, expressions, overallExpressions, pages, converted;
        if (version === 'current') {
            title = survey.schema.title;
            instruction = survey.schema.description;
            image = survey.schema.logo;
            pages = convertApiJsonToQuestions(survey.schema);

        } else {
            const result = await surveyService.getSurveyHistory(survey._id, version);
            title = result.schema.title
            instruction = result.schema.description;
            image = result.schema.logo;
            pages = convertApiJsonToQuestions(result.schema);
        }
        converted = pages[0];
        questions = converted.questions;
        surveyTimer = converted.surveyTimer;
        expressions = converted.expressions;
        overallExpressions = converted.overallExpressions;
        setPages(pages);
        setTitle(title);
        setInstruction(instruction);
        setImage(image);
        setQuestions(questions);
        setTimer(surveyTimer);
        setExpressions(expressions);
        setOverallExpressions(overallExpressions);
        setSelectedPage(0);
    }

    const handleVersionChange = (version) => {
        setSelectedVersion(version);
        fetchPreviousVersion(version);
    };

    const handleTabChange = (tabIndex) => {
        setSelectedPage(tabIndex);
        setQuestions(pages[tabIndex].questions);
    }

    const handleAddPage = () => {
        setPages([...pages, { title: `Page ${pages.length + 1}`, questions: [], questionOrder: 'initial' }]);
        setSelectedPage(pages.length);
        setQuestions([]);
    };

    const handleDeletePage = (pageIndex) => {
        if (pages.length > 1) {
            setPages(pages.filter((_, index) => index !== pageIndex));
        }
    };

    const questionModalClose = () => {
        const pages = convertApiJsonToQuestions(survey.schema);
        const { questions, } = pages[selectedPage];
        setQuestions(questions);
        onClose();
    }

    const questionToEdit = editingQuestionIndex >= 0 ? questions[editingQuestionIndex] : null;

    return (
        <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
            <Box>
                <SurveyInformation
                    survey={survey}
                    selectedVersion={selectedVersion}
                    handleVersionChange={handleVersionChange}
                    title={title}
                    setTitle={setTitle}
                    instruction={instruction}
                    setInstruction={setInstruction}
                    image={image}
                    setImage={setImage}
                    endTime={endTime}
                    handleEndTimeChange={handleEndTimeChange}
                    timer={timer}
                    setTimer={setTimer}
                />
                <PagesManagement
                    pages={pages}
                    setPages={setPages}
                    selectedPage={selectedPage}
                    handleTabChange={handleTabChange}
                    handleAddPage={handleAddPage}
                    handleDeletePage={handleDeletePage}
                    handleAddQuestionClick={handleAddQuestionClick}
                    handleEditQuestion={handleEditQuestion}
                    handleDeleteQuestion={handleDeleteQuestion}
                />
                <Box>
                    <Heading size="md" mt={4}>
                        Expressions (Individual)
                        <Tooltip label={<Text>Syntax: sum(&#123;Question Name 1&#125;, &#123;Question Name 2&#125;)<br/>Available Operation: sum, avg, max, min</Text>} fontSize="md">
                        <IconButton
                            aria-label="Help"
                            icon={<InfoOutlineIcon />}
                            size="sm"
                            ml={2}
                            variant="outline"
                        />
                    </Tooltip>
                    </Heading>
                    
                    <ExpressionEditor
                        expressions={expressions}
                        handleExpressionNameChange={handleExpressionNameChange}
                        handleExpressionChange={handleExpressionChange}
                        handleDeleteExpression={handleDeleteExpression}
                    />
                    <Button mt={4} onClick={handleAddExpression}>
                        Add Expression
                    </Button>
                </Box>
                <Box>
                    <Heading size="md" mt={4}>
                        Expressions (Overall)
                        <Tooltip label={<Text>Syntax: sum(Question Name 1, Question Name 2)<br/>Available Operation: sum, avg, stdev</Text>} fontSize="md">
                            <IconButton
                                aria-label="Help"
                                icon={<InfoOutlineIcon />}
                                size="sm"
                                ml={2}
                                variant="outline"
                            />
                        </Tooltip>
                    </Heading>
                    <ExpressionEditor
                        expressions={overallExpressions}
                        handleExpressionNameChange={handleOverallExpressionNameChange}
                        handleExpressionChange={handleOverallExpressionChange}
                        handleDeleteExpression={handleDeleteOverallExpression}
                    />
                    <Button mt={4} onClick={handleAddOverallExpression}>
                        Add Expression
                    </Button>
                </Box>

                <Modal isOpen={isOpen} onClose={questionModalClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{(editingQuestionIndex === -1 ? "Add Question" : "Edit Question")}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <QuestionEditor
                                onSubmit={handleAddOrUpdateQuestionAndClose}
                                editingQuestion={questionToEdit}
                                onCancelEdit={() => setEditingQuestionIndex(-1)}
                                defaultQuestionName={(selectedPage + 1) + "Q" + (questions.length + 1)}
                                allowedTypes={allowedTypes}
                                handleClose={questionModalClose}
                            />
                        </ModalBody>
                        {/* <ModalFooter>
                            <Button variant={"outline"} onClick={questionModalClose}>
                                Close
                            </Button>
                        </ModalFooter> */}
                    </ModalContent>
                </Modal>
                <Button mt={4} onClick={handleSave} colorScheme="blue">
                    Save Draft
                </Button>
                <Button mt={4} ml={4} onClick={() => navigate('/admin/survey')}>
                    Back
                </Button>
            </Box >
        </Flex>
    );
};

export default SurveySchemaCreator;
