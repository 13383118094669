import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';

import UserManagementTable from './UserManagementTable';
import UserManagementService from '../../services/usermanagement.service';

function UserManagement() {
  const [users, setUsers] = useState([]);

  const fetchData = async () => {
    try {
      const response = await UserManagementService.getAllUsers();
      setUsers(response);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const onRefreshData = () => {
    fetchData();
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <UserManagementTable
        title="Users"
        captions={['Username','Email', 'Roles', 'Actions']}
        data={users}
        onRefreshData={onRefreshData}
      />
    </Flex>
  );
}

export default UserManagement;
