import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
  useDisclosure,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const Links = ['Survey List'];

function PublicNavLink({ children }) {
  return (
    <NavLink to="/public/">
      <Link
        px={2}
        py={1}
        rounded="md"
        _hover={{
          textDecoration: 'none',
          bg: useColorModeValue('gray.200', 'gray.700'),
        }}
        fontWeight="900"
        href="#"
      >
        {children}
      </Link>
    </NavLink>
  );
}

export default function Simple() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { email } = useSelector((state) => state.public);

  return (
    <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <IconButton
          size="md"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Open Menu"
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems="center">
          <Box>Competenece Profile Evaluation </Box>
          <HStack
            as="nav"
            spacing={4}
            display={{ base: 'none', md: 'flex' }}
          >
            {Links.map((link) => (
              <PublicNavLink key={link}>{link}</PublicNavLink>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems="center">
          <Text>{email}</Text>
        </Flex>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as="nav" spacing={4}>
            {Links.map((link) => (
              <PublicNavLink key={link}>{link}</PublicNavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
