import axiosInstance from './axiosIntance';

const register = async (username, email, password) => {
  try {
    const result = await axiosInstance.post('/auth/signup', {
      username,
      email,
      password,
    });
    return result;
  } catch (err) {
    console.error(err);
    throw (err);
  }
};

const login = async (username, password) => {
  try {
    const result = await axiosInstance.post('/auth/signin', {
      username,
      password,
    });
    return result.data;
  } catch (err) {
    console.error(err);
    throw (err);
  }
};

const verifyToken = async () => {
  try {
    const result = await axiosInstance.post('/auth/verifytoken');
    return result.data;
  } catch (err) {
    console.error(err);
    throw (err);
  }
};

const getUserInfo = async (id) => {
  try {
    const result = await axiosInstance.post('/user/' + id);
    return result.data;
  } catch (err) {
    console.error(err);
    throw (err);
  }
};

const changePassword = async (oldpassword, newpassword) => {
  try {
    await axiosInstance.post('/auth/changepassword', {oldpassword, newpassword});
    return true;
  } catch (err) {
    console.error(err);
    if (err.response.status === 403) {
      throw ('Current password is incorrect.')
    } else {
      throw (err);
    }
  }
}

const logout = async () => {
  try {
    await axiosInstance.post('/auth/logout', );
    return true;
  } catch (err) {
    console.error(err);
    throw (err);
  }
};

const functions = {
  register,
  login,
  logout,
  verifyToken,
  getUserInfo,
  changePassword,
  logout
};

export default functions;
