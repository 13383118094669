import axiosInstance from './axiosIntance';

const publicLogin = async (email, link, firstName, lastName) => {
  try {
    const result = await axiosInstance.post('/public/login', { email, link });
    localStorage.setItem('link', link);
    localStorage.setItem('email', email);
    if (firstName !== undefined && firstName !== "") localStorage.setItem('firstName',firstName);
    if (lastName !== undefined && lastName !== "") localStorage.setItem('lastName', lastName);
    return result.data;
  } catch (err) {
    console.error(err);
    throw (err.response.data.message);
  }
};

const submitResponse = async (email, firstName, lastName, startTime, endTime, surveyId, response, linkId) => {
  try {
    const result = await axiosInstance.post('/public/response', { email, firstName, lastName, startTime, endTime, surveyId, response, linkId });
    return result.data;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const functions = {
  publicLogin,
  submitResponse,
};

export default functions;
