import {
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FaCheck } from 'react-icons/fa';

function SurveyListTableRow(props) {
  const {
    title, timeLimit, isCompleted, surveyId,
  } = props;
  const textColor = useColorModeValue('gray.700', 'white');

  return (
    <Tr>
      <Td minWidth={{ sm: '250px' }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {title}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {timeLimit !== undefined ? timeLimit/60 + ' mins' : 'Unlimited'}
          </Text>
        </Flex>
      </Td>
      <Td>
        {isCompleted && (
          <Flex alignItems="center">
            <Icon as={FaCheck} color="green.500" mr="0.5rem" />
            <Text fontSize="md" color={textColor} fontWeight="bold">
              Completed
            </Text>
          </Flex>
        )}
        {!isCompleted && (
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            In Progress
          </Text>
        )}
      </Td>
      <Td>
        {!isCompleted && (
          <NavLink to={`/public/survey?id=${surveyId}`}>
            <Button
              p="0px"
              bg="transparent"
              variant="no-hover"
              disabled={isCompleted}
            >
              <Text
                fontSize="md"
                color="gray.400"
                fontWeight="bold"
                cursor="pointer"
              >
                Go to Survey
              </Text>
            </Button>
          </NavLink>
        )}
      </Td>
    </Tr>
  );
}

export default SurveyListTableRow;
