import React from 'react';
import { Navigate, useOutlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ChakraProvider, Portal } from '@chakra-ui/react';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AdminNavbar from '../components/Navbars/AdminNavbar';
import Sidebar from '../components/Sidebar';
import MainPanel from '../components/Layout/MainPanel';
import PanelContainer from '../components/Layout/PanelContainer';
import PanelContent from '../components/Layout/PanelContent';
import theme from '../theme/theme.js';
import { protectedNavigations } from './routes';

export function ProtectedRoot() {
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const outlet = useOutlet();
  const location = useLocation();

  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
  };

  const getAdminRoutes = (routes) => {
    return routes.filter(e=>e.role === "ROLE_ADMIN");
  }

  const getRoleNavigations = () => {
    if (!user.roles.includes('ROLE_ADMIN')) {
      return protectedNavigations.filter(e=>e.role !== "ROLE_ADMIN")
    } else {
      return protectedNavigations;
    }
  }

  document.documentElement.dir = 'ltr';

  if (!isLoggedIn) {
    return (
      <Navigate
        to={{ pathname: '/admin/loading', state: { from: location } }}
        replace
      />
    );
  }

  if (!user.roles.includes('ROLE_ADMIN')) {
    const activeRoute = getActiveRoute(protectedNavigations);
    const adminRoutes = getAdminRoutes(protectedNavigations);

    if (adminRoutes.filter(e=>e.name === activeRoute).length > 0) {
      return <h1>Forbidden</h1>
    }
  }

  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <Sidebar
        routes={getRoleNavigations()}
        logoText="DASHBOARD"
        display="none"
        sidebarVariant="transparent"
      />
      <MainPanel
        w={{
				  base: '100%',
				  xl: 'calc(100% - 275px)',
        }}
      >
        <Portal>
          <AdminNavbar
            logoText="PURITY UI DASHBOARD"
            brandText={getActiveRoute(protectedNavigations)}
            fixed
          />
        </Portal>
        <PanelContent>
          <PanelContainer>
            {outlet}
          </PanelContainer>
        </PanelContent>
      </MainPanel>
    </ChakraProvider>
  );
}
