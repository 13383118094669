// chakra imports
import { Box, ChakraProvider, Flex } from '@chakra-ui/react';
import React from 'react';
import { useOutlet } from 'react-router-dom';

import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import theme from '../theme/theme.js';
import PublicNavbar from '../components/Navbars/PublicNavbar';

export default function PublicSurvey() {
  const wrapper = React.createRef();
  const outlet = useOutlet();

  React.useEffect(() => {
    document.body.style.overflow = 'unset';
    return function cleanup() { };
  });

  const navRef = React.useRef();
  document.documentElement.dir = 'ltr';
  return (
    <ChakraProvider theme={theme} resetCss={false} w="100%">
      <Box ref={navRef} w="100%">
        <Box w="100%">
          <Box ref={wrapper} w="100%">
            <PublicNavbar />
            {outlet}
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
}
