import axiosInstance from './axiosIntance';

const getSurveySchema = async (id) => {
  try {
    const result = await axiosInstance.get(`/public/survey?id=${id}`);
    return result.data.schema;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
};

const getAllSurveyList = async () => {
  try {
    const result = await axiosInstance.get('/survey?isAll=true');
    return result.data;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const getSurveyList = async () => {
  try {
    const result = await axiosInstance.get('/survey');
    return result.data;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const getSurveyDetail = async (id) => {
  try {
    const result = await axiosInstance.get('/survey?id=' + id);
    return result.data;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const saveSurveyDraft = async (id, schema) => {
  try {
    await axiosInstance.post('/survey-draft', { surveyId: id, schema });
    return true;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const updateSurvey = async (id, data) => {
  try {
    await axiosInstance.put('/survey', { surveyId: id, data });
    return true;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const approveSurvey = async (id, isApproved) => {
  try {
    await axiosInstance.post('/approve-survey', { surveyId: id, isApproved });
    return true;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const newSurvey = async (data) => {
  try {
    await axiosInstance.post('/survey', data);
    return true;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const deleteSurvey = async (surveyId) => {
  try {
    await axiosInstance.delete(`/survey?surveyId=${surveyId}`);
    return true;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const unlockSurvey = async (surveyId) => {
  try {
    const result = await axiosInstance.post(`/unlock-survey`, { surveyId });
    return result.data.result;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const getSurveyResponses = async (surveyId) => {
  try {
    const result = await axiosInstance.get(`/responses?surveyId=${surveyId}`);
    return result.data;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const getSurveyHistory = async (surveyId, version) => {
  try {
    const result = await axiosInstance.get(`/survey/${surveyId}/version/${version}`);
    return result.data;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const getStatistics = async () => {
  try {
    const result = await axiosInstance.get(`/stats`);
    return result.data;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const uploadImage = async (file) => {
  try {
    let formData = new FormData();
    formData.append("image", file);
    const result = await axiosInstance.post(`/upload`, formData);
    return result.config.baseURL + "/images/" + result.data.filename;
  } catch (err) {
    console.error(err);
    throw (err.response.data.error);
  }
}

const functions = {
  getSurveySchema,
  getAllSurveyList,
  getSurveyList,
  getSurveyDetail,
  saveSurveyDraft,
  updateSurvey,
  newSurvey,
  deleteSurvey,
  getSurveyResponses,
  approveSurvey,
  unlockSurvey,
  getSurveyHistory,
  getStatistics,
  uploadImage
};

export default functions;
