import React, { useState } from 'react';
import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Button,
  HStack,
} from '@chakra-ui/react';
import UserEditModal from './UserAddEditModal';
import usermanagementService from '../../services/usermanagement.service';

function TablesTableRow(props) {
  const { id, username, email, roles, onRefreshData, isSelf } = props;
  const textColor = useColorModeValue('gray.700', 'white');

  const [isOpen, setIsOpen] = useState(false);

  const handleEditClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    onRefreshData();
  };

  const handleDeleteClick = async () => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await usermanagementService.deleteUser(id);
        onRefreshData();
      } catch (err) {
        console.error('Error deleting user:', err);
      }
    }
  };

  return (
    <>
      <Tr>
        <Td minWidth={{ sm: '250px' }} pl="0px">
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={textColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {username}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {email}
          </Text>
        </Td>
        <Td>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {roles.map((role) => role.name).join(", ")}
          </Text>
        </Td>
        <Td>
          <HStack spacing={4}>
          <Button
            size="sm"
            colorScheme="teal"
            variant="outline"
            onClick={handleEditClick}
            isDisabled={isSelf}
          >
            Edit
          </Button>
          <Button
            size="sm"
            colorScheme="red"
            variant="outline"
            onClick={handleDeleteClick}
            isDisabled={isSelf}
          >
            Delete
          </Button>
          </HStack>
          
        </Td>
      </Tr>

      <UserEditModal
        isOpen={isOpen}
        onClose={handleClose}
        id={id}
        email={email}
        roles={roles.map(r => r.name)}
      />
    </>
  );
}

export default TablesTableRow;
