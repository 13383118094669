import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  HStack,
} from '@chakra-ui/react';
import { Input, InputGroup, InputLeftElement, InputRightElement, IconButton } from '@chakra-ui/react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import UserManagmentTableRow from './UserManagementTableRow';
import UserEditModal from './UserAddEditModal';

function UserManagementTable({ title, captions, data, onRefreshData }) {
  const textColor = useColorModeValue('gray.700', 'white');
  const { user } = useSelector((state) => state.auth);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleCreateUserClick = () => {
    setIsCreateUserModalOpen(true);
  };

  const handleCloseCreateUserModal = () => {
    setIsCreateUserModalOpen(false);
    onRefreshData();
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const clearSearchTerm = () => {
    setSearchTerm('');
  };

  const filteredData = data.filter((row) =>
    row.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
      <CardHeader p="6px 0px 22px 0px">
        <HStack w="100%" justify="space-between">
          <InputGroup mr={4}>
            <InputLeftElement
              pointerEvents="none"
              children={<FaSearch color="gray.300" />}
            />
            <Input
              type="text"
              placeholder="Search username..."
              value={searchTerm}
              onChange={handleSearch}
            />
            <InputRightElement>
              <IconButton
                aria-label="Clear search"
                icon={<FaTimes />}
                onClick={clearSearchTerm}
                isRound
                size="sm"
                display={searchTerm ? "inline-flex" : "none"}
              />
            </InputRightElement>
          </InputGroup>
          <Button colorScheme="teal" onClick={handleCreateUserClick}>
            Create User
          </Button>
        </HStack>
      </CardHeader>
      <CardBody>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {captions.map((caption, idx) => (
                <Th color="gray.400" key={idx} ps={idx === 0 ? '0px' : null}>
                  {caption}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((row) => (
              <UserManagmentTableRow
                key={`${row.email}-${row.username}`}
                id={row._id}
                username={row.username}
                email={row.email}
                roles={row.roles}
                onRefreshData={onRefreshData}
                isSelf={row._id === user.id}
              />
            ))}
          </Tbody>
        </Table>
      </CardBody>
      <UserEditModal
        isOpen={isCreateUserModalOpen}
        onClose={handleCloseCreateUserModal}
        isCreateMode={true}
        id={''}
        email={''}
        roles={[]}
      />
    </Card>
  );
}

export default UserManagementTable;
